:root {
  --color1: #edf0fa;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: var(--color1);
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14px;
}
body > iframe {
  display: none;
}
@media (max-width: 750px) {
  html {
    font-size: 12px !important;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 8px !important;
  }
}