.overview__main {
    width: 100%;
    padding: 1vw;
    margin-top: 1vw;
    display: flex;
    gap: 1vw;
}


.overview__main__left,
.overview__main__right {
    display: flex;
    flex-direction: column;
}


.overview__main__left {
    flex: 1;
}


.overview__main__right {
    flex: 1;
}


.overview__card_title {
    text-align: center;
    font-size: 1.5rem;
}


h2.overview__card_title2 {
    margin-top: 3vw !important;
    text-align: center;
    font-size: 1.5rem;
}


.overview__select {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2vw;
}

.overview__main .navigation__links {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.overview__main .navigation__link {
    text-decoration: none;
    color: #00a5a7;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem;
}

.overview__main .navigation__link:hover {
    color: red;
}

@media (max-width: 750px) {
    .overview__main {
        flex-direction: column;
    }
}