.overview__left_content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1.5vw;
}


.overview__left_content__stats {
    display: grid;
    gap: 0.75vw;
}


.left_content__stats_item {
    text-align: center;
    padding: 0.25vw;
    /* border-radius: 5px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75); */
}


.overview__left_content__table {
    flex: 1;
    display: flex;
    flex-direction: column;
    --table-gap: 0.5vw;
    --table-key-width: 9vw;
    border: 0.15vw solid;
    border-radius: 0.5vw;
}


.left_content__table_head,
.left_content__table_item {
    display: flex;
}


.left_content__table_head_key {
    font-weight: bold;
    border-bottom: 1px solid;
    /* background-color: rgb(143, 123, 224); */
}


.left_content__table_item_key {
    border-top: 1px solid;
}


.left_content__table_item:nth-child(2n + 1) {
    background-color: rgb(83, 209, 125);
}


.left_content__table_item:nth-child(2n) {
    background-color: rgb(149, 217, 244);
}


.left_content__table_head_key,
.left_content__table_item_key {
    flex: 1;
    padding: 5px 0;
    min-width: var(--table-key-width);
    text-align: center;
}


.stats_item_key {
    font-weight: 600;
    margin-bottom: 1vw;
}


@media (max-width: 750px) {
    .overview__left_content {
        justify-content: space-around;
    }

    .overview__left_content__table {
        --table-key-width: 12vw;
    }
}