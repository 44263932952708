.map__component {
    width: 100%;
    aspect-ratio: 3/2;
    background-color: white;
    border-radius: 1rem;
    /* overflow: hidden; */
}

.leaflet-container {
    width: 100%;
    aspect-ratio: 1/1;
    z-index: 20;
}

.leaflet-marker-icon {
    filter: hue-rotate(270deg);
}
.map__component_addedClass > div {
    width: 100%;
    aspect-ratio: 2.5/1 !important;
    background-color: white;
    border-radius: 1rem;
}

/* .leaflet-container_addedClass{
    width: 100%;
    aspect-ratio: 2.5/1 ;
    z-index: 20;
}  */
.histogram {
    width: inherit;
  }