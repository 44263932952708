.query_main {
  width: 100%;
  padding: 1vw;
  margin-top: 1vw;
  display: flex;
  gap: 1vw;
}
.query_left_panel,
.query_right_panel {
  display: flex;
  flex-direction: column;
}


.query_left_panel {
  flex: 1;
}


.query_right_panel {
  flex: 1;
}
.query_right_panel {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 90%;
  /* height: 40%; */
}

/* .query_right_panel_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 5%;
  border-bottom: 1px solid #ccc;
}

.farmer_details {
  flex: 1;
}

.queryImage img {
  width: 40%;
  height: 40%;
  object-fit: cover;
}

.query_right_panel_bottom {
  padding: 2% 5%;
}

.query_submission {
  display: flex;
  align-items: center;
}

.response_box {
  flex: 1;
  padding: 1% 2%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-right: 2%;
}

.submit_response_button {
  background-color: #007bff;
  color: #fff;
  padding: 1% 2%;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.submit_response_button:hover {
  background-color: #0056b3;
}
 */


 .query_right_panel_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 5%;
  border-bottom: 1px solid #ccc;
}

.farmer_details {
  flex: 1;
}

/* .queryImage {
  width: 200px;
  height: 200px; 
  flex-shrink: 0;
  overflow: hidden;
}

.queryImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.query_right_panel_bottom {
  padding: 2% 5%;
}

.query_submission {
  display: flex;
  align-items: center;
}

.response_box {
  flex: 1;
  padding: 1% 2%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-right: 2%;
}

.submit_response_button {
  background-color: #007bff;
  color: #fff;
  padding: 1% 2%;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.submit_response_button:hover {
  background-color: #0056b3;
} */




.queryImage {
position: relative;
width: 200px; /* Adjust the width as needed */
height: 200px; /* Adjust the height as needed */
flex-shrink: 0;
overflow: hidden;
}

.queryImage img {
width: 100%;
height: 100%;
object-fit: cover;
cursor: pointer;
}

.fullImageOverlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
align-items: center;
justify-content: center;
z-index: 999;
}

.fullImageContainer {
max-width: 90%;
max-height: 90%;
}

.fullImageContainer img {
width: auto;
height: auto;
max-width: 100px;
max-height: 100px;
}

.closeButton {
position: absolute;
top: 10px;
right: 10px;
color: #fff;
font-size: 20px;
cursor: pointer;
}

.response_box {
flex: 1;
padding: 1% 2%;
border: 1px solid #ccc;
border-radius: 0.5rem;
margin-right: 2%;
}

.submit_response_button {
background-color: #007bff;
color: #fff;
padding: 1% 2%;
border: none;
border-radius: 0.5rem;
cursor: pointer;
}

.submit_response_button:hover {
background-color: #0056b3;
}