.main{
    position: fixed;
    top: 20%;
    left: 76%;
    height: 60vh;
    width:23vw;
    overflow: auto;
    z-index:1000;
    padding-left: 2rem;
}
.Name{
    padding-bottom:15px;
}
.department{
    padding-bottom:15px;
}
.employeeID{
    padding-bottom:15px;
}
.email{
    padding-bottom:15px;
}