.RTWS_main{
    margin-top: 1.5vw;
    display: flex;
    gap: 1.5vw;
    margin-right: 1vw;
    padding-bottom: 1vw;
}
.RTWS_filter_form{
    display:flex;
    flex-direction: row;
}
.form_body img{
    height:90vh;
    margin-top:1%;
    margin-left: 50%;
    margin-right: 50%;
}
.loader{
    font-size: 2rem;
}