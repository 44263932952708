.topbar {
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 100;
    max-width: 100vw;
    box-shadow: 0px 0px 5px 0px;
}


.topbar-wrapper {
    display: flex;
    align-items: center;
    padding: 1vw 2.5vw;
    column-gap: 1vw;
    justify-content: space-between;
}


.topbar-left {
    display: flex;
    gap: 3vw;
    align-items: center;
    flex: 1;
}


.topbar-left-left {
    display: flex;
    align-items: center;
    margin: auto;
}


.topbar-left-right {
    display: flex;
    align-items: center;
    gap: 3vw;
    margin-left: 3vw;
    justify-content: space-around;
}


.route-title {
    font-size: 1.25rem;
}


.search-container {
    position: relative;
}


input[type="text"].search,
.topbar-select {
    width: 25vw;
    height: 3vw;
    border: 0.15vw solid rgba(0, 0, 0, 0.2);
    border-radius: 2vw;
    padding-left: 1.5vw;
    padding-right: 2.5vw;
    outline: none;
    font-size: 1rem;
}


.search-icon {
    height: 1.8vw;
    position: absolute;
    right: 0.5vw;
    cursor: pointer;
    opacity: 0.45;
}


.topbar-right {
    display: flex;
    gap: 1vw;
}


.topbar-right-icon {
    height: 3vw;
    cursor: pointer;
}


.topbar-logo {
    width: 15.3vw;
    margin-top: -1vw;
}


.topbar-form {
    display: flex;
    align-items: center;
    position: relative;
}

.dropdown__list {
    position: absolute;
    left: 0.5rem;
    overflow: hidden;
    border: 1px solid grey;
}

.dropdown__item {
    padding: 1rem 1.2rem;
    background-color: white;
    border-bottom: 1px solid gray;
    cursor: pointer;
}

.dropdown__item:hover {
    background-color: #f7edd1;
}


@media (max-width: 750px) {
    .active {
        display: none;
    }


    input[type="text"].search,
    .topbar-select {
        height: 4vw;
    }
}


@media (max-width: 500px) {
    .topbar-logo {
        width: 20vw;
    }

    .topbar-left-left {
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    .topbar-left-right {
        width: 100%;
        margin-top: 2vw;
        margin-left: 0;
    }

    .route-title {
        margin: auto;
    }


    .search-container {
        width: 100%;
        
    }


    input[type="text"].search,
    .topbar-select {
        width: 100%;
        height: 5vh;
    }


    .no_content {
        margin-top: 0;
    }
}
.topbar_notification{
    display:flex;
    flex-direction: column;
}