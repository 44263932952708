.compare__main {
    width: 98%;
    display: flex;
    margin: auto;
    gap: 1vw;
    padding-top: 1vw;
}

.compare__left,
.compare__right {
    flex: 1;
}


.compare__searchbox {
    display: flex;
    align-items: center;

    position: relative;
    margin: auto;
    margin-bottom: 2vw;
}

.compare__searchbox input {
    width: 100% !important;
}


.combined__graphs {
    padding: 0.75rem;

}

.combined__graphsContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 325px;
}


.combined__title {
    font-size: 2.5rem;
    font-weight: bolder;
    text-align: center;
    padding: 1rem;
}



.compare__chart__nodata__title {
    font-size: 2rem;
}

.dateRange__form input,
button {
    font-size: 1.25rem !important;
}

@media (max-width:750px) {
    .compare__main {
        width: 100%;
        flex-direction: column;
        row-gap: 3vw;
        align-items: center;
    }
}