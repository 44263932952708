.chart__wrapper {
    display: flex; 
    flex-direction: column;
    margin-bottom: 1.5rem;
    margin-top: 20px;
    gap: 20px;
}

.chart__wrapper .chart__yaxis__label {
    writing-mode: vertical-rl;
    text-orientation: upright;
    margin-top: -4rem;
    /* font-size: 100%; */
}
.chart_chart {
    height: 350px !important;
}
.chart__wrapper .chart_chart {
    flex: 1;
}
.chart__select {
    font-size: 1.25rem !important;
}

.chart__controller__inputs {
    display: flex;
    justify-content: space-evenly;
    /* margin-bottom: 2.5rem; */
    flex-wrap: wrap;
    align-items: center;
}

.dateRange__form {
    display: flex;
    gap: 10px;
    width:30%;
    border: 1px dashed;
    padding: 2px;
    flex-wrap: wrap;
}

/* @media (max-width: 500px) {
    .chart__controller__inputs {
        flex-direction: column;

    }
} */