.escorts-logo {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 1rem;
}

.login-form-container {
    display: flex;
    justify-content: center;
}

.login-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 275px;
}

.login-form input,
.login-form select {
    padding: 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid black;
}

.login-form input:focus {
    border: 1.5px solid black;
}

.login-form button {
    padding: 10px;
    margin-top: 15px;
    background-color: #0fa8aa;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.goto-signUp-container {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.goto-comment {
    color: rebeccapurple;
}

.goto-signUp-btn {
    width: 100%;
    padding: 10px;
    background-color: #eb1c22;
    color: white;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border: none;
}