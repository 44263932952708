.notification{
    position: fixed;
    top: 20%;
    left: 76%;
    height: 60vh;
    width:23vw;
    overflow: auto;
    z-index:1000;
}
.notificationBar{
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-radius: 10px;
    border-color: black;
}
.notificationBar h3{
    color:red;
}