.card {
    font-size: 1rem;
    background-color: white;
    padding: 2vw;
    border-radius: 2vw;
}


.overview__main__part {
    flex: 1;
}