
.main {
  background-color: white;
  padding-bottom: 1rem;
  border-radius: 1rem;
  /* height: 500px; */
}

.download_window_main {
  /* Your styles for the download window */
}

.pseudo_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

  h1 {
    /* Styles for the heading */
  }
  
  .download_first {
  }
  
  .get_latest_button,
  .get_all_button {
    /* Styles for the buttons */
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* .active {
    
    background-color: #c0c0c0;
    color: white;
  } */
  
  .list_data {
    /* Styles for the data list */
  }
  
  .all_apk_versions {
    /* Styles for the "All APK versions" heading */
  }
  
  
    .mb-2 {
        /* Styles for the mb-3 div */
        margin-bottom: 1.5rem;
        text-align: center;
        border: 1px solid #ccc;
        padding: 1rem;
      }
  .download_button{
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #64b5de;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  a {
    /* Styles for the download links */
    color: #0000ee;
    text-decoration: none;
  }
  
  a:hover {
    /* Styles for the hovered download links */
    text-decoration: underline;
  }
  