.navbar-card {
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.8vw;
    border-radius: 0 3vw 3vw 0;
    position: sticky;
    top: 7.6vw;
    transition: width 0.15s ease, height 0.15s ease;
    transition-delay: 0.1s; 
}


.nav-option {
    overflow: hidden;
    width: 100%;
    min-height: 3.4vw;
    background-color: #f0f0f0;
    padding: 0.8vw;
    border-radius: 0.5vw;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}


.nav-option:hover {
    background-color: #0fa8aa;
}


.nav-icon {
    height: 2.2vw;
    width: 2.2vw;
}


.nav-option-text {
    height: 3vw;
}


.nav-option-with-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width:500px) {

    .nav-icon {
        height: 3vw;
        width: 3vw;
    }
}