.agro-control-main {
    width:100vw;
    margin-top: 15px;
    display: flex;
    gap: 1.5vw;
    margin-right: 10px;
    padding-bottom: 1rem;
}

.agro-main-content {
    width:91%;
}

.agro-results-container {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 76px;
    left: 31%;
    background-color: white;
    padding: 10px;
    border-radius: 12px;
}

.agro-result-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px;
    cursor: pointer;

}

.agro-result-item :hover {
    background-color: #0fa8aa;
}

.agro-farmlist {
    background-color: white;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    margin: 20px 0;
}

.agro-farmItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    cursor: pointer;
    border: 1px solid;
}

.agro-farmItem:hover {
    background-color: #0fa8aa;

}

.agro-flex-child:first-child {
    margin-right: 20px;
}

.agro__body__main {
    display: flex;
    gap: 25px;
}

.agro__left,
.agro__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.chart__select__container {
    margin: 0.75rem 0px;
}

.agro-table__farmer,
.agro-table__iot {
    background: white;
    padding: 0.5rem 0.75rem;
    border-radius: 0.75rem;
    width: 100%;
}

.agro-table__name {
    font-size: larger;
    font-weight: bold;
}

.agro__table__main {
    display: flex;
    margin-top: 5px;
    gap: 20px;
}

.agro-table__farmer .agro__table__left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.agro-table_data_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    align-items: center;
}

.agro-table__iot .agro__table__left,
.agro-table__iot .agro__table__right {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.agro-hide {
    display: none;
}

.agro__network {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.agro-Right__layer1 {
    display: flex;
    width: 100%;
}

.agro-Right__layer1 .agro-queries {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70px;
    justify-content: space-between;
    color: white;
    padding: 10px;
    cursor: pointer;
}

.agro-Right__layer1 .agro-queries:nth-child(1) {
    background-color: #8573FE;
    border-radius: 10px 0 0 10px;
}

.agro-Right__layer1 .agro-queries:nth-child(2) {
    background-color: #65C7FE;
}

.agro-Right__layer1 .agro-queries:nth-child(3) {
    background-color: #FF9066;
    border-radius: 0 10px 10px 0;
}

.agro-Right__layer2 {
    display: flex;
    gap: 20px;
}

.agro-Right__layer2 .agro-sensors {
    display: flex;
    justify-content: center;
}

.agro-sensors .agro-sensors__stats__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    min-width: max-content;
}

.agro-sensors .agro-sensors__stats__item:nth-child(1) {
    border-radius: 10px 0 0 10px;
    border-right: 1px solid #d9d9d9;
}

.agro-sensors .agro-sensors__stats__item:nth-child(2) {
    border-radius: 0 10px 10px 0;
    border-left: 1px solid #d9d9d9;
}

.agro-download__report {
    flex: 1;
    padding: 15px;
    background: #55A3FFE8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.agro-Right__layer4 {
    display: flex;
    gap: 10px;
}

.agro-Right__layer4 .agro-voltage__item__container {
    flex: 1;
    min-height: 172px;
}


.agro-float-child {
    width: 100%;
    float: left;
    border: 1px solid gray;
}

.agro-float-child1 {
    width: 44%;
    float: left;
}

.agro-float-child2 {
    width: 55%;
    float: right;
    margin-left: 0.5%;
}

.agro-crop_table_grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid gray;
    padding: 4%;
    border-radius: 4px;
    width: 100%;
}

.agro_table_grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid gray;
    padding: 4%;
    border-radius: 4px;
    width: 120%;
    /* margin-left: -3%; */
}

.agro__right__table__title {
    font-weight: bold;
    font-size: large;
    text-align: center;
    padding: 0.5rem;
}



.agro__right__table__body {
    display: block;
    /* max-height: 200px; */
    /* overflow-y: scroll; */
}


.agro__right__thead,
.agro__right__table__body .agro__right__table__row {
    display: table;
    width: 100%;
    table-layout: fixed;
    border:1px solid black;
}

/* .agro__right__table__head {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
} */
.toggle_button{
    background-color: transparent;
    font-size: 1rem !important; 
    border-radius: 1rem;
}
.farm_details{
    background-color: white;
    border-radius: 1rem;
    display: flex ;
    flex-direction: row;
    justify-content:space-around;
    margin:1rem ;
    padding-top:1rem;
}
.current_crop {
    display: flex;
    flex-direction: row;
}
.farm_name{
    display: flex;
    flex-direction: row;
}
.farm_area{
    display: flex;
    flex-direction: row;
}

@media (max-width: 1050px) {

    .agro-Right__layer1,
    .agro-Right__layer2 {
        font-size: 0.9rem;
    }

    .agro__left,
    .agro__right {
        font-size: 1rem;
    }
}


@media (max-width: 950px) {

    .agro-Right__layer1,
    .agro-Right__layer2 {
        font-size: 0.75rem;
    }
}

@media (max-width: 750px) {
    .agro__body__main {
        display: grid;
    }

    .agro-Right__layer4 {
        flex: 2;
    }


    .agro__left,
    .agro__right {
        width: 98%;
    }

    .agro-Right__layer1 {
        font-size: 1rem;
    }

    .agro-Right__layer1 .agro-queries {
        height: 7vw;
    }

    .agro-Right__layer4 {
        flex-wrap: nowrap;
    }

}

@media (max-width:500px) {

    .agro-float-child{
        width: 100%;
        display:flex;
        flex-direction: column;
    }
    .agro__left,
    .agro__right {
        width: 97%;
    }
    .agro-float-child1 {
        width: 100%;
        /* float: left; */
    }
    
    .agro-float-child2 {
        width: 100%;
        margin-left: 0.5%;
    }
}