.wind-compass {
    top:50%;
    background-color: white;
    z-index: 100000;
    width: 70px;
    height: 70px;
    border: 2px solid #333;
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
    margin-left:88%;
    margin-top:27%;
  }
  
  .compass-needle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 4px;
    height: 30px;
    background-color: red;
    transform: translateX(-50%);
    transform-origin: 50% 100%;
  }
  
  .compass-direction {
    font-size: 20px;
  }
  