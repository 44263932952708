/* .query_card_main {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .query_card_main:hover {
    background-color: #eaeaea;
  }
  
  .query_card_main_selected {
    background-color: #eeec7b;
  }
  
  .qid,
  .prompt,
  .date,
  .status {
    margin-right: 10px;
  }
  
  .qid {
    font-weight: bold;
  }
  
  .prompt {
    flex-grow: 1;
  }
  
  .date {
    font-size: 12px;
    color: gray;
  }
  
  .status {
    font-size: 14px;
    font-weight: bold;
  }
  
  */






/*   
  .query_card_main {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    cursor: pointer;
   
  }
  
  .query_card_main:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .query_card_main_selected {
    display: flex;
    flex-direction: column;
    background-color: #dac75b;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  
  .query_card_main .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .query_card_main .qid {
    font-size: 16px;
    font-weight: bold;
    margin-right: 12px;
  }
  
  .query_card_main .prompt {
    font-size: 14px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .query_card_main .date,
  .query_card_main .status {
    font-size: 12px;
    color: #888888;
  }
   */
  
  
   .query_card_main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .query_card_main_selected {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #fffacd; /* Light yellow background when selected */
  }
  
  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .qid,
  .prompt,
  .status {
    color: #333;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .date {
    font-size: 12px;
    color: #999;
    align-self: flex-end; /* Aligns date to the right within the flex container */
  }