.iot-control-main {
    margin-top: 1.5vw;
    display: flex;
    gap: 1.5vw;
    margin-right: 1vw;
    padding-bottom: 1vw;
}

.iot__body h1 {
    font-size: 2.5rem !important;
}

/* .navbar-container {
      height: calc(100vh - 90px);
      position: sticky;
      top: 76px;
  } */

.results-container {
    min-width: 50vw;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 7.6vw;
    left: 31%;
    background-color: white;
    padding: 1vw;
    border-radius: 1.2vw;
}

.result-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1vw solid rgba(0, 0, 0, 0.3);
    padding: 1vw;
    cursor: pointer;
}

.result-item:hover {
    background-color: #0fa8aa;
}

.farmList {
    background-color: white;
    padding: 1vw 2vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    border-radius: 2vw;
    margin: 2vw 0;
}

.farmItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1vw 1.5vw;
    cursor: pointer;
    border: 0.1vw solid;
}

.farmItem:hover {
    background-color: #0fa8aa;
}

.iot__body__main {
    display: flex;
    gap: 1.5vw;
}

.iot__left,
.iot__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.table__farmer,
.table__iot {
    background: white;
    padding: 1.5vw 2vw;
    border-radius: 2vw;
    width: 100%;
}

.table__iot {
    min-height: 172px;
}

.table__name {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 1vw;
}

.table__top__flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vw;
    flex-wrap: wrap;
}

.farm__selector__dropdown {
    display: inline;
    font-size: 1rem !important;
}

.iot__table__main {
    display: flex;
    margin-top: 0.5vw;
    gap: 2vw;
}

.table__farmer .iot__table__left {
    flex: 1;
    display: grid;
    flex-direction: column;
    gap: 1vw;
}

.table_data_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1vw solid rgba(128, 128, 128, 0.3);
}

.table_item_key {
    color: gray;
}

.table_item_unit {
    color: rgb(110, 0, 255);
}

.table__iot .iot__table__left,
.table__iot .iot__table__right {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.table__iot .iot__table__left {
    flex: 2;
}

.table__iot .iot__table__right {
    flex: 3;
}

.hide {
    display: none;
}


.iot__network {
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.iotRight__layer1 {
    display: flex;
    width: 100%;
}


.iotRight__layer1 .queries {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 6vw;
    justify-content: space-between;
    color: white;
    padding: 1vw;
    cursor: pointer;
}


.iotRight__layer1 .queries:nth-child(1) {
    background-color: #8573fe;
    border-radius: 1vw 0 0 1vw;
}


.iotRight__layer1 .queries:nth-child(2) {
    background-color: #65c7fe;
}


.iotRight__layer1 .queries:nth-child(3) {
    background-color: #ff9066;
    border-radius: 0 1vw 1vw 0;
}


.iotRight__layer2 {
    display: flex;
    gap: 1vw;
}


.iotRight__layer2 .sensors {
    /* flex: 2; */
    display: flex;
    justify-content: center;
    width: 100%;
}


.sensors .sensors__stats__item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1vw;
    background-color: white;
    min-width: max-content;
}


.sensors .sensors__stats__item:nth-child(1) {
    border-radius: 1vw 0 0 1vw;
    border-right: 0.1vw solid #d9d9d9;
}


.sensors .sensors__stats__item:nth-child(2) {
    border-radius: 0 1vw 1vw 0;
    border-left: 0.1vw solid #d9d9d9;
}


.download__report {
    flex: 1;
    margin: 1rem 0;
    padding: 1.5vw;
    background: #55a3ffe8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    border-radius: 1vw;
    
    /* cursor: pointer; */
}


.iotRight__layer4 {
    display: flex;
    gap: 1vw;
}


.iotRight__layer4 .voltage__item__container {
    flex: 1;
    min-height: 17.2vw;
    width: 80px;
}


.voltage__item__container .voltage__reading {
    font-size: 2vw;
    margin-top: 1vw;
    width: 100%;
}

.voltage__reading {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.iot__chart {
    min-height: 325px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}


.iot__chart__container {
    width: 100%;
}


.iot-main-content {
    width: 91%;
}


h2 {
    font-size: 1.5rem !important;
}

.iot__multichart {
    margin: 2rem 0;
}

.download__report a {
    text-decoration: none;
    color: white;
}
.farm_details p{
    font-size: 1.25rem !important;
}



@media (max-width: 1050px) {

    .iotRight__layer1,
    .iotRight__layer2 {
        font-size: 0.9rem;
    }

    .iot__left,
    .iot__right {
        font-size: 1rem;
    }
}


@media (max-width: 950px) {

    .iotRight__layer1,
    .iotRight__layer2 {
        font-size: 0.75rem;
    }
}

@media (max-width: 750px) {
    .iot__body__main {
        display: grid;
    }

    .iot__table__main2 {
        display: grid;
    }

    .iotRight__layer4 .voltage__item__container {
        flex: 2;
    }


    .iot__left,
    .iot__right {
        width: 98%;
    }

    .iotRight__layer1 {
        font-size: 1rem;
    }

    .iotRight__layer1 .queries {
        height: 7vw;
    }

    .iotRight__layer4 {
        flex-wrap: nowrap;
    }

    #gauge-chart-solar-voltage,
    #gauge-chart-battery-voltage {
        margin-left: 0;
        transform: scale(1);

    }

    .voltage__reading {
        height: 25vw;
        width: 30vw !important;
    }

    .voltage__item__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}

@media (max-width:500px) {

    .iot__left,
    .iot__right {
        width: 97%;
        font-size: 1.75rem !important;
    }

    .voltage__reading {
        height: 25vw;
        width: 30vw !important;
    }
}