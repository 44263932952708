.farmerDirectory-main {
    width:100vw;
    margin-top: 15px;
    display: flex;
    gap: 1.5vw;
    margin-right: 10px;
    padding-bottom: 1rem;
}
.table-container {
    max-height: 700px; /* Adjust the maximum height as needed */
    overflow-y: auto;
  }
  
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #fff; /* Add a background color if needed */
    z-index: 1; /* To layer it above the table body */
  }